import { useEffect, useState } from 'react'
import NotFound from '404'
import Survey from 'Survey'
import { validateSurvey } from 'utils'
import Admin from 'Admin'
function App() {
	const [shop, setShop] = useState(null)
	const [admin, setAdmin] = useState(false)
	const [loader, setLoader] = useState(true)
	const [alert, setAlert] = useState(null)
	const getShopName = async () => {
		let params = window.location.href.split('/')
		params = params[params.length - 1].replaceAll('#', '').replaceAll('?', '').trim()
		if (params === 'admin') {
			setAdmin(true)
			setLoader(false)
			return
		}
		params = atob(params)
		const isValid = await validateSurvey(params)
		if (isValid.status) {
			setShop(isValid.data)
		} else {
			setLoader(false)
		}
	}
	const closeAlert = e => {
		e.preventDefault()
		setAlert({ type: alert.type + ' hiden', message: alert.message })
		setTimeout(() => setAlert(null), 300)
	}
	useEffect(() => {
		getShopName()
	}, [shop])
	return (
		<div className="App">
			{alert && (
				<div className={`alert ${alert.type}`}>
					<p>{alert.message}</p>
					<a href="#" className="close" onClick={closeAlert}></a>
				</div>
			)}
			{loader && <div className="loader"></div>}
			{shop && shop !== '' && <Survey shop={shop} setLoader={setLoader} setAlert={setAlert} />}
			{admin && <Admin setLoader={setLoader} setAlert={setAlert} />}
			{!shop && !admin && <NotFound />}
		</div>
	)
}
export default App
