import { useEffect, useState } from 'react'
import { jsonImporter, cssImporter, faviconImporter, reqBack, backgroundImporter } from 'utils'
import Section from 'components/Section'
import TitleSection from 'components/TitleSection'
import AskContainer from 'components/AskContainer'
import SelectLanguage from 'components/SelectLanguage'
export default function Survey({ shop, setLoader, setAlert }) {
	const [questions, setQuestions] = useState({})
	const [currentSection, setCurrentSection] = useState(0)
	const [languages, setLanguages] = useState(null)
	const [answers, setAnswers] = useState({})
	const [startDateSurvey, setstartDateSurvey] = useState(null)
	const [showThankyou, setShowThankyou] = useState(false)
	const importAndInitSurvey = async () => {
		const json = await jsonImporter(shop)
		if (json.status) {
			const { data } = json
			setQuestions(data)
			// El iconos
			if (data.icon) {
				faviconImporter(data.icon)
			}
			// El titulo
			if (data.title) {
				document.title = data.title
			}
			// El fondo
			if (data.background) {
				backgroundImporter(data.background)
			}
			// Si es multileguaje
			if (data.languages) {
				setLanguages(data.languages)
			}
			cssImporter(shop)
			const date = new Date()
			setstartDateSurvey(date.getTime())
			setCurrentSection(0)
			setLoader(false)
		}
	}
	const validateSection = () => {
		const totalQuestionsSection = questions.sections[currentSection].questions.length || 0
		let totalAnswerSection = 0
		for (const keyAnswer of Object.keys(answers)) {
			if (answers[keyAnswer].value && Number(keyAnswer.split('_')[1]) === currentSection) totalAnswerSection++
		}
		return totalQuestionsSection === totalAnswerSection
	}
	const next = e => {
		e.preventDefault()
		if (validateSection()) {
			setCurrentSection(currentSection + 1)
			window.scrollTo(0, 0)
		} else {
			setAlert({ type: 'warning', message: 'Por favor responde las preguntas' })
		}
	}
	const back = e => {
		e.preventDefault()
		setCurrentSection(currentSection - 1)
	}
	const answer = (data, indexQuestion, indexSection) => {
		const answersCopy = JSON.parse(JSON.stringify(answers))
		answersCopy[`${indexQuestion}_${indexSection}`] = data
		setAnswers(answersCopy)
	}
	const sendAnswers = async e => {
		e.preventDefault()
		if (validateSection()) {
			setLoader(true)
			const date = new Date()
			const result = await reqBack('setAnswers', {
				shop,
				start: startDateSurvey,
				end: date.getTime(),
				answers
			})
			setLoader(false)
			if (result.status) {
				setShowThankyou(true)
			} else {
				setAlert({ type: 'warning', message: 'Tenemos problemas de conexión, intentalo de nuevo más tarde' })
			}
		} else {
			setAlert({ type: 'warning', message: 'Por favor responde las preguntas' })
		}
	}
	useEffect(() => {
		importAndInitSurvey()
	}, [])

	return (
		<>
			<div className="background" id="background"></div>
			{languages && <SelectLanguage languages={languages} />}
			<div className="container-form">
				<div className="container-survey">
					{!showThankyou && questions.sections && (
						<>
							{questions.sections.map((section, i) => (
								<Section key={i} active={currentSection === i}>
									{section.title && <TitleSection text={section.title} />}
									{section.questions.map((ask, e) => (
										<AskContainer
											key={e}
											question={ask}
											onAnswer={data => answer(data, e, i)}
											labelAndvalue={answers[`${i}_${e}`]}
										/>
									))}
								</Section>
							))}
							<div className="next-and-back-container">
								{currentSection > 0 && (
									<a href="#" className="back" onClick={back}>
										{questions.backTitle || 'Volver'}
									</a>
								)}
								{currentSection < questions.sections.length - 1 && (
									<a href="#" className="next" onClick={next}>
										{questions.nextTitle || 'Siguiente'}
									</a>
								)}
								{currentSection === questions.sections.length - 1 && (
									<a href="#" className="next" onClick={sendAnswers}>
										{questions.sendTitle || 'Enviar'}
									</a>
								)}
							</div>
						</>
					)}
					{showThankyou && (
						<Section active={true}>
							<TitleSection text={questions.title} />
							<div className="thankyou">
								<p>{questions.thankyou}</p>
								{questions.googleLink && (
									<a href={questions.googleLink} className="link-opinate-google">
										Califícanos en Google
									</a>
								)}
							</div>
						</Section>
					)}
				</div>
			</div>
		</>
	)
}
