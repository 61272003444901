import { useEffect } from 'react'
import { initTranslate } from 'utils'
export default function Select({ languages }) {
	useEffect(() => {
		initTranslate(languages)
	}, [])
	return (
		<div className="language">
			<div id="google_translate_element"></div>
		</div>
	)
}
