import { useState } from 'react'
export default function Select({ options, name, onSelect, jsonClass }) {
	const [selected, setSelected] = useState(null)
	const getImage = img => {
		const image = require(`img/resources/${img}`)
		return image
	}
	return (
		<div className={`select-container ${jsonClass || ''}`}>
			{options.map((option, i) => (
				<label
					key={i}
					style={{
						...(option.width ? { width: option.width } : {})
					}}
					className={`${selected === option.value ? 'selected ' : ''}${selected !== null && selected !== option.value ? ' no-selected ' : ''}${option.img ? ' with-image ' : ''}${option.class ? option.class : ''}`}>
					{option.img && (
						<img
							src={getImage(option.img)}
							style={{
								...(option.imgWidth ? { width: option.imgWidth } : {})
							}}
						/>
					)}
					{option.label && <span>{option.label}</span>}
					<input
						type="radio"
						name={name}
						value={option.value}
						onClick={() => {
							if (selected === option.value) {
								setSelected(null)
								onSelect(null)
							} else {
								setSelected(option.value)
								onSelect(option.value)
							}
						}}
					/>
				</label>
			))}
		</div>
	)
}
