import Select from './Select'
export default function AskContainer({ question, onAnswer, labelAndvalue }) {
	return (
		<div className="ask-container">
			<label>{question.label}</label>
			{question.type === 'select' && (
				<Select
					name={question.label}
					jsonClass={question.class}
					options={question.options}
					value={labelAndvalue}
					onSelect={value =>
						onAnswer({
							label: question.label,
							value
						})
					}
				/>
			)}
		</div>
	)
}
