import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const imgPath =
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
		? 'http://localhost/satisfaction/backend/img'
		: './backend/img'
export const validateSurvey = async shop => {
	const data = await reqBack('validateSurvey', { shop })
	return data
}
export const backgroundImporter = image => {
	const img = document.createElement('img')
	img.src = `${imgPath}/${image}`
	document.getElementById('background').appendChild(img)
}
export const cssImporter = async name => {
	const data = await reqBack('getCss', { shop: name })
	if (data.status) {
		const style = document.createElement('style')
		style.innerHTML = data.data
		document.head.appendChild(style)
	}
}
export const jsonImporter = async file => {
	try {
		const data = await reqBack('getQuestions', { shop: file })
		return data
	} catch (error) {
		console.log(error)
		return { status: false, data: error }
	}
}
export const faviconImporter = image => {
	let link = document.querySelector("link[rel~='icon']")
	if (!link) {
		link = document.createElement('link')
		link.rel = 'icon'
		document.getElementsByTagName('head')[0].appendChild(link)
	}
	link.href = `${imgPath}/${image}`
}
export const initTranslate = languages => {
	const addScript = document.createElement('script')
	addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
	document.body.appendChild(addScript)
	languages = languages.join(',').toLowerCase()
	setTimeout(() => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: 'auto',
				autoDisplay: false,
				includedLanguages: languages,
				layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
			},
			'google_translate_element'
		)
	}, 1000)
}
export const Authtenticate = async (username, password) => {
	const data = await reqBack('login', { username, password })
	return data
}
export const reqBack = async (action, data) => {
	try {
		const host =
			!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
				? 'http://localhost/satisfaction/backend/index.php'
				: './backend/index.php'
		let r = await fetch(host, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({ action, data })
		})
		r = await r.json()
		return r
	} catch (error) {
		console.error(error)
		return { status: false, data: error }
	}
}
export function exportToXLS(headers, rows, fileName) {
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
	const fileExtension = '.xlsx'
	const data = [headers, ...rows.map(row => Object.values(row))]
	const workSheet = XLSX.utils.aoa_to_sheet(data)
	const workBook = {
		Sheets: { data: workSheet, cols: [] },
		SheetNames: ['data']
	}
	const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
	const fileData = new Blob([excelBuffer], { type: fileType })
	FileSaver.saveAs(fileData, fileName + fileExtension)
}
