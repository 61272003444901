import { useState, useEffect } from 'react'
import Login from 'components/Admin/Login'
import { reqBack, exportToXLS } from 'utils'
const Admin = ({ setLoader, setAlert }) => {
	const [isLogged, setIsLogged] = useState(false)
	const [title, setTitle] = useState('')
	const [headersTable, setHeadersTable] = useState([])
	const [rowsTable, setRowsTable] = useState([])
	const [widgets, setWidgets] = useState({})

	const getTimeSurvey = duration => {
		const seconds = parseInt((duration / 1000) % 60)
		const minutes = parseInt(duration / 1000 / 60)
		let hours = 0
		if (minutes > 59) {
			hours = parseInt(minutes / 60)
		}
		return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
	}
	const parseDateToFormat12 = date => {
		const hours = date.getHours() % 12 || 12
		const minutes = date.getMinutes()
		const seconds = date.getSeconds()
		const ampm = date.getHours() >= 12 ? ' PM' : ' AM'
		return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds} ${ampm}`
	}

	const getAnswers = async () => {
		if (!isLogged) {
			return
		}
		setLoader(true)
		const answers = await reqBack('getAnswers', { shop: isLogged })
		if (answers.status === false) {
			setAlert({ type: 'warning', message: 'No se pudieron obtener las respuestas' })
			setLoader(true)
			return
		}
		let averageTime = 0
		const headersTable = ['Fecha de respuesta', 'Hora de inicio', 'Hora de finalización', 'Duración de la encuesta']
		const rowsTable = []
		answers.data.answers.forEach(answer => {
			const answersRow = {}
			for (const key in answer.answers) {
				answersRow[answer.answers[key].label] = answer.answers[key].value
				if (!headersTable.includes(answer.answers[key].label)) {
					headersTable.push(answer.answers[key].label)
				}
			}
			const start = new Date(answer.start)
			const end = new Date(answer.end)
			averageTime += end - start
			rowsTable.push({
				'Fecha de respuesta': `${start.getDate()}/${start.getMonth()}/${start.getFullYear()}`,
				'Hora de inicio': parseDateToFormat12(start),
				'Hora de finalización': parseDateToFormat12(end),
				'Duración de la encuesta': getTimeSurvey(end - start),
				...answersRow
			})
		})
		setRowsTable(rowsTable)
		setHeadersTable(headersTable)
		setWidgets({
			countAnswers: rowsTable.length,
			averageTime: getTimeSurvey(averageTime / rowsTable.length)
		})
		setLoader(false)
	}
	const logout = e => {
		e.preventDefault()
		setHeadersTable([])
		setRowsTable([])
		setWidgets({})
		setIsLogged(false)
	}
	const exportData = e => {
		e.preventDefault()
		const filename = title.toLowerCase().replaceAll(' ', '_')
		exportToXLS(headersTable, rowsTable, 'respuestas_' + filename)
	}
	useEffect(() => {
		getAnswers()
	}, [isLogged])
	if (isLogged) {
		return (
			<div className="admin-panel">
				<div className="menu-panel">
					<div>
						<p>{title}</p>
					</div>
					<a href="#" onClick={logout}>
						Cerrar sesión
					</a>
				</div>
				<div className="widgets">
					<div>
						<span>Cantidad de despuestas</span>
						<p>{widgets.countAnswers}</p>
					</div>
					<div>
						<span>Tiempo promedio en responder</span>
						<p>{widgets.averageTime}</p>
					</div>
					<a href="#" className="excel" onClick={exportData}>
						<img src={require('img/resources/excel.jpg')} />
						Exportar respuestas a excel
					</a>
				</div>
				<div className="table-container">
					<span>Resultados de la encuesta</span>
					<div className="table">
						<table>
							<thead>
								<tr>
									{headersTable.map((header, index) => (
										<th key={index}>{header}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{rowsTable.map((row, index) => (
									<tr key={index}>
										{headersTable.map((header, index) => (
											<td key={index}>{row[header]}</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	} else {
		return <Login setIsLogged={setIsLogged} setLoader={setLoader} setTitle={setTitle} setAlert={setAlert} />
	}
}

export default Admin
