import { useState } from 'react'
import { Authtenticate } from 'utils'
const Login = ({ setIsLogged, setLoader, setAlert, setTitle }) => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const handleUsernameChange = e => setUsername(e.target.value)
	const handlePasswordChange = e => setPassword(e.target.value)

	const handleSubmit = async e => {
		e.preventDefault()
		if (username !== '' && password !== '') {
			setLoader(true)
			const result = await Authtenticate(username, password)
			if (result.status === false) {
				setLoader(false)
				setAlert({ type: 'warning', message: 'Usuario o contraseña incorrectos' })
				return
			}
			setLoader(false)
			setIsLogged(result.data.name)
			setTitle(result.data.title)
		}
	}

	return (
		<div className="login-section">
			<img src={require('img/resources/logo.png')} />
			<h2>Ingresar</h2>
			<form onSubmit={handleSubmit}>
				<div>
					<label htmlFor="username">Usuario:</label>
					<input
						type="text"
						id="username"
						value={username}
						placeholder="Ingresa tu usuario"
						onChange={handleUsernameChange}
					/>
				</div>
				<div>
					<label htmlFor="password">Contraseña:</label>
					<input
						type="password"
						id="password"
						placeholder="********"
						value={password}
						onChange={handlePasswordChange}
					/>
				</div>
				<button type="submit">Ingresar</button>
			</form>
		</div>
	)
}

export default Login
